<template>
  <div class="authorization">
    <div class="container-xl">
      <div class="card">
        <div class="card-header clearfix">
          <div class="card-title float-sm-left">Заявка на статус инвестора</div>
          <div class="text-muted float-sm-right pt-1">
            {{ userStatusLabel }}
          </div>
        </div>
        <div v-if="profile.lenderInfoRequested" class="card-body">
          <div class="card border-info">
            <div class="card-body">
              <div class="h6 m-0 p-0 text-info">Необходима дополнительная информация</div>
              <div class="text-info mt-2">{{ profile.lenderNotes }}</div>
            </div>
          </div>
        </div>
        <div class="card-body position-relative">
          <data-loading :loading="dataLoading" :lock="true"/>
          <form @input="validateForm" @change="validateForm" @submit.prevent="submitForm">
            <div class="row">
              <div class="col-12 col-md-4">
                <div class="form-group">
                  <label for="lastName">Фамилия</label>
                  <input v-model="identification.lastName" class="form-control" id="lastName"
                         placeholder="Константинопольский" required>
                  <input-errors :errors="errors.lastName"/>
                </div>
              </div>
              <div class="col-12 col-md-4">
                <div class="form-group">
                  <label for="firstName">Имя</label>
                  <input v-model="identification.firstName" class="form-control" id="firstName" placeholder="Константин"
                         required>
                  <input-errors :errors="errors.firstName"/>
                </div>
              </div>
              <div class="col-12 col-md-4">
                <div class="form-group">
                  <label for="middleName">Отчество</label>
                  <input v-model="identification.middleName" class="form-control" id="middleName"
                         placeholder="Константинович">
                  <input-errors :errors="errors.middleName"/>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-12 col-md-3">
                <SingleDateInput
                    :initialDate="parsedBirthday"
                    label="Дата рождения"
                    @update="onBirthdayUpdate"
                />
                <input-errors :errors="errors.birthday"/>
              </div>
              <div class="col-12 col-md-8">
                <div class="form-group">
                  <label for="birthplace">Место рождения</label>
                  <input v-model="identification.birthplace" class="form-control" id="birthplace"
                         placeholder="Место рождения" required>
                  <input-errors :errors="errors.birthplace"/>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-12">
                <div class="form-group">
                  <label for="registerAddress">Адрес регистрации</label>
                  <textarea v-model="identification.registerAddress"
                            class="form-control"
                            id="registerAddress"
                            placeholder="Адрес регистрации"
                            required></textarea>
                  <input-errors :errors="errors.registerAddress"/>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-12 col-md-4">
                <div class="form-group">
                  <label for="passportNumber">Номер и серия паспорта</label>
                  <input-mask v-model="identification.passportNumber"
                              mask="9999 999999"
                              class="form-control"
                              id="passportNumber"
                              placeholder="0000 000000"
                              autocomplete="off"
                              required/>
                  <input-errors :errors="errors.passportNumber"/>
                </div>
              </div>
              <div class="col-12 col-md-3">
                <SingleDateInput
                    :initialDate="parsedPassportIssuedDate"
                    label="Когда выдан"
                    @update="onPassportIssuedDateUpdate"
                />
                <input-errors :errors="errors.passportIssuedDate"/>
              </div>
              <div class="col-12 col-md-4">
                <div class="form-group">
                  <label for="passportDepartmentCode">Код подразделения</label>
                  <input-mask v-model="identification.passportDepartmentCode"
                              mask="999-999"
                              class="form-control"
                              id="passportDepartmentCode"
                              placeholder="000-000"
                              autocomplete="off"
                              required/>
                  <input-errors :errors="errors.passportDepartmentCode"/>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-12">
                <div class="form-group">
                  <label for="passportIssuedBy">Кем выдан</label>
                  <textarea v-model="identification.passportIssuedBy"
                            class="form-control"
                            id="passportIssuedBy"
                            placeholder="Кем выдан"
                            required></textarea>
                  <input-errors :errors="errors.passportIssuedBy"/>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-12 col-md-8 col-lg-6">
                <div class="h6">Загрузите скан паспорта</div>
                <div class="form-group">
                  <file-upload :files="identification.passportFirstScan" :multiple="false">
                    Первый разворот с фотографией
                  </file-upload>
                  <input-errors :errors="errors.passportFirstScan"/>
                </div>
                <div class="form-group">
                  <file-upload :files="identification.passportSecondScan" :multiple="false">
                    Второй разворот с пропиской
                  </file-upload>
                  <input-errors :errors="errors.passportSecondScan"/>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-12 col-md-6">
                <div class="form-group">
                  <label for="inn">ИНН</label>
                  <input-digits v-model="identification.inn"
                                maxlength="12"
                                class="form-control"
                                id="inn"
                                placeholder="000000000000"
                                autocomplete="off"
                                required/>
                  <input-errors :errors="errors.inn"/>
                </div>
              </div>
              <div v-if="profile.isEntrepreneur" class="col-12  col-md-6">
                <div class="form-group">
                  <label for="ogrnip">ОГРНИП</label>
                  <input-mask v-model="identification.ogrnip"
                              mask="999999999999999"
                              class="form-control"
                              id="ogrnip"
                              placeholder="000000000000000"
                              autocomplete="off"
                              required/>
                  <input-errors :errors="errors.ogrnip"/>
                </div>
              </div>
            </div>
            <div v-if="profile.isLegalEntity" class="row">
              <div class="col-12 col-md-6">
                <div class="form-group">
                  <label for="companyName">Название компании</label>
                  <input v-model="identification.companyName"
                         type="text"
                         class="form-control"
                         id="companyName"
                         placeholder="Название компании"
                         required>
                  <input-errors :errors="errors.companyName"/>
                </div>
              </div>
              <div class="col-12 col-md-6">
                <div class="form-group">
                  <label for="companySiteUrl">Сайт компании</label>
                  <input v-model="identification.companySiteUrl" type="url" class="form-control" id="companySiteUrl"
                         placeholder="Сайт компании">
                  <input-errors :errors="errors.companySiteUrl"/>
                </div>
              </div>
            </div>
            <div v-if="profile.isLegalEntity" class="row">
              <div class="col-12 col-md-6">
                <div class="form-group">
                  <label for="companyInn">ИНН (юр. лицо)</label>
                  <input-digits v-model="identification.companyInn"
                                maxlength="10"
                                class="form-control"
                                id="companyInn"
                                placeholder="0000000000"
                                autocomplete="off"
                                required/>
                  <input-errors :errors="errors.companyInn"/>
                </div>
              </div>
              <div class="col-12 col-md-6">
                <div class="form-group">
                  <label for="companyKpp">КПП</label>
                  <input-mask v-model="identification.companyKpp"
                              mask="999999999"
                              class="form-control"
                              id="companyKpp"
                              placeholder="000000000"
                              autocomplete="off"
                              required/>
                  <input-errors :errors="errors.companyKpp"/>
                </div>
              </div>
            </div>
            <div v-if="profile.isLegalEntity" class="row">
              <div class="col-12 col-md-6">
                <div class="form-group">
                  <label for="companyOgrnip">ОГРН</label>
                  <input-mask v-model="identification.companyOgrnip"
                              mask="9999999999999"
                              class="form-control"
                              id="companyOgrnip"
                              placeholder="0000000000000"
                              autocomplete="off"
                              required/>
                  <input-errors :errors="errors.companyOgrnip"/>
                </div>
              </div>
              <div class="col-12">
                <div class="form-group">
                  <label for="companyAddress">Адрес регистрации (юр. лицо)</label>
                  <textarea v-model="identification.companyAddress"
                            class="form-control"
                            id="companyAddress"
                            placeholder="Адрес регистрации (юр. лицо)"
                            required></textarea>
                  <input-errors :errors="errors.companyAddress"/>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-12 col-md-6">
                <div class="form-group">
                  <label for="phone">Подтверждение номера телефона</label>
                  <div class="row">
                    <div class="col-7">
                      <input-mask v-model="identification.phone"
                                  mask="+7 (999) 999-9999"
                                  class="form-control"
                                  id="phone"
                                  placeholder="+7 (000) 000-0000"
                                  autocomplete="off"
                                  required/>
                      <input-errors :errors="errors.phone"/>
                    </div>
                    <div class="col-5">
                      <button v-if="needPhoneVerification" @click="sendCode" type="button" class="btn btn-outline-info">
                        Получить код
                      </button>
                      <span v-if="!needPhoneVerification" class="text-info d-inline-block mt-1">
                        <i class="bi-check"></i>
                        Подтвержден
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div v-if="needPhoneVerification" class="col-12 col-md-6">
                <div class="form-group">
                  <label for="verificationCode">Код</label>
                  <div class="row">
                    <div class="col-7">
                      <input-mask v-model="verificationCode"
                                  mask="999999"
                                  class="form-control"
                                  id="verificationCode"
                                  placeholder="000000"
                                  autocomplete="off"
                                  required/>
                      <input-errors :errors="errors.verificationCode"/>
                    </div>
                    <div class="col-5">
                      <button @click="verifyPhone" type="button" class="btn btn-info">
                        Подтвердить
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="row" v-if="!profile.isPasswordSet">
              <div class="col-12 col-md-6">
                <div class="form-group">
                  <div class="row">
                    <div class="col-6">
                      <label for="password">Пароль</label>
                      <div class="input-group input-group-lg">
                        <input v-model="password" :type="passwordVisible ? 'text' : 'password'" class="form-control"
                               id="password" required>
                        <div class="input-group-append">
                          <a class="btn btn-light" @click="togglePassword()">
                            <i :class="{ 'bi-eye-slash': passwordVisible, 'bi-eye': !passwordVisible}"></i>
                          </a>
                        </div>
                      </div>
                      <input-errors :errors="errors.password"/>
                    </div>
                    <div class="col-6">
                      <label for="password">Повторите пароль</label>
                      <div class="input-group input-group-lg">
                        <input v-model="repeatPassword" :type="repeatPasswordVisible ? 'text' : 'password'"
                               class="form-control"
                               id="password" required>
                        <div class="input-group-append">
                          <a class="btn btn-light" @click="toggleRepeatPassword()">
                            <i :class="{ 'bi-eye-slash': repeatPasswordVisible, 'bi-eye': !repeatPasswordVisible}"></i>
                          </a>
                        </div>
                      </div>
                      <input-errors :errors="errors.repeatPassword"/>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <hr>
            <div class="row">
              <div class="col-12">
                <div class="form-group">
                  <div class="custom-control custom-checkbox">
                    <input v-model="identification.userContractTerms" type="checkbox" class="custom-control-input"
                           id="userContractTerms" required>
                    <label class="custom-control-label" for="userContractTerms"></label>
                    <public-link to="/page/dogovor-po-sodeistviiu-v-investirovanii"
                                 target="_blank"
                    >Согласие с условиями по содействию в инвестировании
                    </public-link>
                  </div>
                  <input-errors :errors="errors.userContractTerms"/>
                </div>
                <div class="form-group">
                  <div class="custom-control custom-checkbox">
                    <input v-model="identification.personalDataPolicy" type="checkbox" class="custom-control-input"
                           id="personalDataPolicy" required>
                    <label class="custom-control-label" for="personalDataPolicy"></label>
                    <public-link to="/page/soglasie-na-obrabotku-i-peredachu-personalnykh-dannykh"
                                 target="_blank"
                    >Согласие с политикой обработки персональных данных
                    </public-link>
                  </div>
                  <input-errors :errors="errors.personalDataPolicy"/>
                </div>
                <div class="form-group">
                  <div class="custom-control custom-checkbox">
                    <input v-model="identification.loyalProgram" type="checkbox" class="custom-control-input"
                           id="loyalProgram" required>
                    <label class="custom-control-label" for="loyalProgram"></label>
                    <a href="https://cdn.lendly.ru/loyal.pdf"
                       target="_blank"
                    >Согласие с офертой участия в Программе лояльности Лендли
                    </a>
                  </div>
                  <input-errors :errors="errors.loyalProgram"/>
                </div>
              </div>
              <div class="col-12">
                <div class="form-group text-center">
                  <button type="submit" class="btn btn-lg btn-success">Подать заявку</button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
    <processing :show="processing"/>
    <div v-if="profile.lenderDataOnModeration">
      <div class="modal fade show d-block"
           data-backdrop="static"
           data-keyboard="false"
           tabindex="-1"
           role="dialog"
           aria-hidden="true"
      >
        <div class="modal-dialog" role="document">
          <div class="modal-content">
            <div class="modal-body">
              <div class="text-center">
                <img src="@/assets/img/dialog_time.png" alt=""/>
                <div class="h2">Ваша заявка принята!</div>
                <p class="font-2">
                  Будет рассмотрена в течение суток на соответствие требованиям федерального закона № 115-ФЗ
                  "О противодействии легализации (отмыванию) доходов, полученных преступным путем, и финансированию
                  терроризма"
                </p>
                <div class="form-group">
                  <router-link :to="{ name: 'myMoney' }" class="btn btn-lg btn-success">ОК</router-link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="modal-backdrop fade show"></div>
    </div>
  </div>
</template>

<script>
import {DataLoading, FileUpload, InputDigits, InputErrors, Processing, PublicLink} from "@/components/common";
import {Phone} from "@/utils/api-client/models/common/Phone";
import {createProfileManager} from "@/utils/di";
import moment from "moment";
import InputMask from "primevue/inputmask";
import {DatePicker} from "v-calendar";
import userStatusLabels from "./data/userStatusLabels";
import {IdentificationManager} from "./managers/IdentificationManager";
import SingleDateInput from "@/components/common/components/SingleDateInput.vue";

export default {
  name: "Authorization",
  components: {
    SingleDateInput,
    DataLoading,
    DatePicker,
    FileUpload,
    InputDigits,
    InputErrors,
    InputMask,
    Processing,
    PublicLink
  },
  inject: ["profile"],
  data() {
    return {
      identification: {
        passportFirstScan: [],
        passportSecondScan: []
      },
      verificationCode: null,
      errors: {},
      dataLoading: false,
      processing: false,
      verifiedPhones: [],
      passwordVisible: false,
      repeatPasswordVisible: false,
      password: null,
      repeatPassword: null,
    };
  },
  computed: {
    userStatusLabel() {
      return userStatusLabels[this.profile.status] || this.profile.status;
    },
    needPhoneVerification() {
      const identificationPhone = this.getPhoneObject();
      return !identificationPhone || !this.verifiedPhones.some(
          (phone) => phone.countryCode === identificationPhone.countryCode && phone.number === identificationPhone.number
      );
    },
    parsedBirthday() {
      const d = this.identification.birthday;
      return d instanceof Date
          ? {
            day: String(d.getDate()).padStart(2, "0"),
            month: String(d.getMonth() + 1).padStart(2, "0"),
            year: String(d.getFullYear())
          }
          : {
            day: "",
            month: "",
            year: ""
          };
    },
    parsedPassportIssuedDate() {
      const d = this.identification.passportIssuedDate;
      return d instanceof Date
          ? {
            day: String(d.getDate()).padStart(2, "0"),
            month: String(d.getMonth() + 1).padStart(2, "0"),
            year: String(d.getFullYear())
          }
          : {
            day: "",
            month: "",
            year: ""
          };
    }
  },
  async mounted() {
    this.addProfilePhoneToVerified();

    this.dataLoading = true;
    try {
      const manager = new IdentificationManager();
      const identification = await manager.findOne();
      Object.assign(this.identification, identification);
    } finally {
      this.dataLoading = false;
    }
  },
  methods: {
    togglePassword() {
      this.passwordVisible = !this.passwordVisible;
    },
    toggleRepeatPassword() {
      this.repeatPasswordVisible = !this.repeatPasswordVisible;
    },
    validateForm() {
      const errors = {};
      if (!this.identification.lastName) {
        errors.lastName = ["Поле обязательное для заполнения"];
      }
      if (!this.identification.firstName) {
        errors.firstName = ["Поле обязательное для заполнения"];
      }
      if (!this.identification.birthday) {
        errors.birthday = ["Поле обязательное для заполнения"];
      } else if (!moment(this.identification.birthday).isValid()) {
        errors.birthday = ["Дата указана неправильно"];
      }
      if (!this.identification.birthplace) {
        errors.birthplace = ["Поле обязательное для заполнения"];
      }
      if (!this.identification.registerAddress) {
        errors.registerAddress = ["Поле обязательное для заполнения"];
      }
      if (!this.identification.passportNumber) {
        errors.passportNumber = ["Поле обязательное для заполнения"];
      } else if (!/^\d{4} \d{6}$/.test(this.identification.passportNumber)) {
        errors.passportNumber = ["Неправильные серия и номер паспорта"];
      }
      if (!this.identification.passportIssuedDate) {
        errors.passportIssuedDate = ["Поле обязательное для заполнения"];
      } else if (!moment(this.identification.passportIssuedDate).isValid()) {
        errors.passportIssuedDate = ["Дата указана неправильно"];
      }
      if (!this.identification.passportDepartmentCode) {
        errors.passportDepartmentCode = ["Поле обязательное для заполнения"];
      } else if (!/^\d{3}-\d{3}$/.test(this.identification.passportDepartmentCode)) {
        errors.passportDepartmentCode = ["Неправильный код подразделения"];
      }
      if (!this.identification.passportIssuedBy) {
        errors.passportIssuedBy = ["Поле обязательное для заполнения"];
      }
      if (!this.identification.passportFirstScan || this.identification.passportFirstScan.length === 0) {
        errors.passportFirstScan = ["Добавьте первый разворот паспорта с фотографией"];
      }
      if (!this.identification.passportSecondScan || this.identification.passportSecondScan.length === 0) {
        errors.passportSecondScan = ["Добавьте второй разворот паспорта с пропиской"];
      }
      if (!this.identification.inn) {
        errors.inn = ["Поле обязательное для заполнения"];
      } else if (!/^\d{12}$/.test(this.identification.inn)) {
        errors.inn = ["Неправильный ИНН"];
      }
      if (this.profile.isEntrepreneur) {
        if (!this.identification.ogrnip) {
          errors.ogrnip = ["Поле обязательное для заполнения"];
        } else if (!/^\d{15}$/.test(this.identification.ogrnip)) {
          errors.ogrnip = ["Неправильный ОГРНИП"];
        }
      }
      if (this.profile.isLegalEntity) {
        if (!this.identification.companyName) {
          errors.companyName = ["Поле обязательное для заполнения"];
        }
        if (!this.identification.companyInn) {
          errors.companyInn = ["Поле обязательное для заполнения"];
        } else if (!/^\d{10}$/.test(this.identification.companyInn)) {
          errors.companyInn = ["Неправильный ИНН"];
        }
        if (!this.identification.companyKpp) {
          errors.companyKpp = ["Поле обязательное для заполнения"];
        } else if (!/^\d{9}$/.test(this.identification.companyKpp)) {
          errors.companyKpp = ["Неправильный КПП"];
        }
        if (!this.identification.companyOgrnip) {
          errors.companyOgrnip = ["Поле обязательное для заполнения"];
        } else if (!/^\d{13}$/.test(this.identification.companyOgrnip)) {
          errors.companyOgrnip = ["Неправильный ОГРН"];
        }
        if (!this.identification.companyAddress) {
          errors.companyAddress = ["Поле обязательное для заполнения"];
        }
      }
      if (!this.identification.phone) {
        errors.phone = ["Поле обязательное для заполнения"];
      } else if (!/^\+7 \(\d{3}\) \d{3}-\d{4}$/.test(this.identification.phone)) {
        errors.phone = ["Неправильный номер телефона"];
      } else if (this.needPhoneVerification) {
        errors.phone = ["Необходимо подтвердить номер телефона"];
      }
      if (!this.identification.userContractTerms) {
        errors.userContractTerms = ["Необходимо принять соглашение"];
      }
      if (!this.identification.personalDataPolicy) {
        errors.personalDataPolicy = ["Необходимо принять соглашение"];
      }
      if (!this.identification.loyalProgram) {
        errors.loyalProgram = ["Необходимо принять соглашение"];
      }
      if (!this.profile.isPasswordSet) {
        const passwordErrors = this.validatePassword();
        if (passwordErrors.length > 0) {
          errors.password = passwordErrors;
        }
        if (this.password !== this.repeatPassword) {
          errors.repeatPassword = ["Пароли не совпадают"]
        }
      }
      this.errors = errors;
      return Object.keys(errors).length === 0;
    },

    validatePassword() {
      const hasSymbols = this.password.search(/[#!@$%^&*_]/g) > -1;
      const hasUpperCase = this.password.search(/[A-Z]/g) > -1;
      const hasLowerCase = this.password.search(/[a-z]/g) > -1;
      const isLatin = this.password.search(/[^\u0000-\u007F]/g) === -1;
      const hasDigit = this.password.search(/\d/g) > -1;
      const lengthIsEnough = this.password.length >= 8;

      const errors = []
      if (!hasSymbols) {
        errors.push("Пароль должен содержать специальный символ #,!,@,$,%,^,&,*,_<");
      }

      if (!hasUpperCase) {
        errors.push("Пароль должен содержать прописную букву");
      }

      if (!hasLowerCase) {
        errors.push("Пароль должен содержать строчную букву");
      }

      if (!isLatin) {
        errors.push("Пароль должен содержать только латинские буквы");
      }

      if (!hasDigit) {
        errors.push("Пароль должен содержать цифру");
      }

      if (!lengthIsEnough) {
        errors.push("Пароль должен содержать не менее 8 символов");
      }

      return errors;
    },
    validatePhone() {
      if (!this.identification.phone) {
        this.errors.phone = ["Поле обязательное для заполнения"];
        return false;
      }
      if (!/^\+7 \(\d{3}\) \d{3}-\d{4}$/.test(this.identification.phone)) {
        this.errors.phone = ["Неправильный номер телефона"];
        return false;
      }
      return true;
    },
    validateVerificationCode() {
      if (!this.verificationCode) {
        this.errors.verificationCode = ["Поле обязательное для заполнения"];
        return false;
      }
      if (!/^\d{6}$/.test(this.verificationCode)) {
        this.errors.verificationCode = ["Неправильный код"];
        return false;
      }
      return true;
    },
    async submitForm() {
      if (this.validateForm()) {
        this.processing = true;
        try {
          if (this.profile.isPasswordSet === false) {
            const profileManager = await createProfileManager();
            await profileManager.setFirstPassword(this.password);
          }
          const manager = new IdentificationManager();
          await manager.create(this.profile.status, this.identification);
          this.profile.markLenderDataOnModeration();
        } finally {
          this.processing = false;
        }
      }
    },
    async sendCode() {
      if (this.validatePhone()) {
        this.processing = true;
        try {
          const manager = await createProfileManager();
          const result = await manager.addPhone(this.getPhoneObject());
          if (result.phoneVerified) {
            this.verifiedPhones.push(result.phone);
            delete this.errors.phone;
          }
        } finally {
          this.processing = false;
        }
      }
    },
    async verifyPhone() {
      if (this.validatePhone() && this.validateVerificationCode()) {
        this.processing = true;
        try {
          const manager = await createProfileManager();
          const result = await manager.verifyPhone(this.getPhoneObject(), this.verificationCode);
          if (result.phoneVerified) {
            this.verifiedPhones.push(result.phone);
            delete this.errors.phone;
          } else {
            this.errors.verificationCode = ["Неправильный код"];
          }
        } finally {
          this.processing = false;
        }
      }
    },
    getPhoneObject() {
      if (this.identification.phone) {
        const countryCode = "7";
        const number = this.identification.phone.replace(/\D/g, "").substr(1);
        return new Phone(countryCode, number);
      }
    },
    addProfilePhoneToVerified() {
      if (this.profile.phoneVerified) {
        this.verifiedPhones.push(this.profile.phone);
      }
    },
    onBirthdayUpdate({ date, valid }) {
      if (valid) {
        this.identification.birthday = new Date(`${date.year}-${date.month.padStart(2, '0')}-${date.day.padStart(2, '0')}`);
      } else {
        this.identification.birthday = null;
      }
      this.validateForm();
    },
    onPassportIssuedDateUpdate({ date, valid }) {
      if (valid) {
        this.identification.passportIssuedDate = new Date(`${date.year}-${date.month.padStart(2, '0')}-${date.day.padStart(2, '0')}`);
      } else {
        this.identification.passportIssuedDate = null;
      }
      this.validateForm();
    }
  },
  watch: {
    "profile.phone"() {
      this.addProfilePhoneToVerified();
    }
  }
}
</script>

<style lang="scss">
.authorization {
  .modal-dialog {
    .font-2 {
      font-size: 1.5rem;
    }
  }
}
</style>
